import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import request from '../config/request';
import { USER_LOGOIN_SUCCESS } from '../store/types';
import getErrors from '../store/actions/errorActions';
import Loader from '../components/loading/Loader';
import { logout } from '../store/actions/authActions';

const AdminLogin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('access_token');
  const customerId = queryParams.get('customer_id');

  const checkAdmin = useCallback(async () => {
    if (accessToken && customerId) {
      try {
        dispatch(logout());
        const config = {
          headers: {
            'Content-Type': 'application/json',
          },
        };
        const values = { accessToken, customerId };
        const { data } = await request.post(
          `customer/adminlogin`,
          values,
          config,
        );
        dispatch({
          type: USER_LOGOIN_SUCCESS,
          payload: {
            userInfo: data.data.customer,
            userToken: data.data.token,
          },
        });
        navigate('/');
      } catch (e) {
        if (e?.response?.data?.msg) {
          dispatch(getErrors(e?.response?.data?.msg));
        }
      }
    }
  }, [accessToken, customerId, dispatch, navigate]);

  useEffect(() => {
    checkAdmin();
  }, [checkAdmin]);
  return (
    <div>
      <Loader />
    </div>
  );
};

export default AdminLogin;
